var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/ultrawide/1600x/dam/jcr:865e9f8c-46e3-4b74-9fdf-590d52410e85/antao-bath-tub-in-stylish-bathromm.jpg",
      "title": "욕실 컬렉션"
    }
  }), _c('page-section', {
    staticClass: "pt-40px pt-md-80px"
  }, [_c('v-container', {
    staticClass: "mt-50px mt-md-40px"
  }, [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("다양한 인테리어 디자인을 살펴보고 욕실을 완벽하게 갖추는 데 필요한 모든 것을 찾아보세요. 각 컬렉션은 독특한 디자인으로 인상적입니다 - 개인의 모습과 귀하가 원하는 대로 완벽하게 맞춤화된 욕실을 제공합니다. 지금 나에게 꼭 맞는 디자인을 찾아보세요!")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5 py-10px py-md-60px my-20px my-md-10px"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg mb-10"
  }, [_c('h2', {
    staticClass: "tit text-center mb-lg-12"
  }, [_vm._v("제품 영역별 컬렉션")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.subjects, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      staticClass: "grey lighten-5",
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("컬렉션 살펴보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)])], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(" 목욕. 하나의 디자인.")]), _c('h2', {
    staticClass: "tit text-center"
  }, [_vm._v("완벽한 욕실 컬렉션")]), _c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("이 컬렉션을 사용하면 세면대, 변기, 샤워기, 욕조, 가구, 설비 및 액세서리 등 욕실을 하나의 디자인으로 완벽하게 "), _c('br'), _vm._v("꾸밀 수 있습니다. 컬렉션은 서로 완벽하게 조화를 이루는 완벽한 욕실 가구를 제공합니다. "), _c('br'), _vm._v("모든 것이 합쳐져 조화로운 전체 그림을 만들 수 있습니다! ")])]), _c('h3', {
    staticClass: "text-left mb-6"
  }, [_vm._v("11개 컬렉션")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.collections, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("당신을 위해 선정된 주요 주제들")])]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.themes, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12",
        "lg": "6"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--sm grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title mb-16"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("엄선된 컬렉션 하이라이트")])]), _c('p', {
    staticClass: "text-center txt-sm txt mb-16"
  }, [_vm._v("특별한 디자인, 트렌디한 스타일, 기술적 혁신 등 신중한 디자인부터 놀라울 정도로 특별한 디자인까지: "), _c('br'), _vm._v("이 하이라이트 제품을 사용하면 목표한 포인트를 설정하거나 개인 꿈의 욕실을 디자인할 때 "), _c('br'), _vm._v("과감하게 대담한 표현을 할 수 있습니다.")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.collectionHighlight, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": item.image
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("컬렉션 살펴보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }