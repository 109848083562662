<template>
    <client-page>
        <shop-product-visual wide image="https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/ultrawide/1600x/dam/jcr:865e9f8c-46e3-4b74-9fdf-590d52410e85/antao-bath-tub-in-stylish-bathromm.jpg"  title="욕실 컬렉션" />
        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-40px">
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">다양한 인테리어 디자인을 살펴보고 욕실을 완벽하게 갖추는 데 필요한 모든 것을 찾아보세요. 각 컬렉션은 독특한 디자인으로 인상적입니다 - 개인의 모습과 귀하가 원하는 대로 완벽하게 맞춤화된 욕실을 제공합니다. 지금 나에게 꼭 맞는 디자인을 찾아보세요!</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-10px py-md-60px my-20px my-md-10px">
            <v-container>
				<div class="tit-wrap tit-wrap--lg mb-10">
                    <h2 class="tit text-center mb-lg-12">제품 영역별 컬렉션</h2>

					<v-row class="row--lg">
                    <template v-for="item in subjects">
                        <v-col :key="item" cols="6" md="3">
                            <v-card tile flat :to="item.link" class="grey lighten-5">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">컬렉션 살펴보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
                </div>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
					<p class="text-center"> 목욕. 하나의 디자인.</p>
					 <h2 class="tit text-center">완벽한 욕실 컬렉션</h2>
					<p class="txt txt--sm text-center">이 컬렉션을 사용하면 세면대, 변기, 샤워기, 욕조, 가구, 설비 및 액세서리 등 욕실을 하나의 디자인으로 완벽하게 <br>꾸밀 수 있습니다. 컬렉션은 서로 완벽하게 조화를 이루는 완벽한 욕실 가구를 제공합니다. <br>모든 것이 합쳐져 조화로운 전체 그림을 만들 수 있습니다!
					</p>

                </div>
				<h3 class="text-left mb-6">11개 컬렉션</h3>
                <v-row class="row--lg">
                    <template v-for="item in collections">
                        <v-col :key="item" cols="6" md="4">
                            <v-card tile flat :to="item.link">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <h2 class="tit">당신을 위해 선정된 주요 주제들</h2>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in themes">
                        <v-col cols="12" lg="6" :key="item">
                            <v-card tile flat :to="item.link">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--sm grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>


        <page-section id="2">
            <v-container>
                <div class="product-section-title mb-16">
                    <span class="tit font-weight-regular font-tertiary white">엄선된 컬렉션 하이라이트</span>
                </div>
                <p class="text-center txt-sm txt mb-16">특별한 디자인, 트렌디한 스타일, 기술적 혁신 등 신중한 디자인부터 놀라울 정도로 특별한 디자인까지: <br>이 하이라이트 제품을 사용하면 목표한 포인트를 설정하거나 개인 꿈의 욕실을 디자인할 때 <br>과감하게 대담한 표현을 할 수 있습니다.</p>
            
                <v-row class="row--lg">
                    <template v-for="item in collectionHighlight">
                        <v-col :key="item" cols="6" md="3">
                            <v-card tile flat :to="item.link" class="">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">컬렉션 살펴보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>

            </v-container>

        </page-section>


    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import OverviewRevolution from "@/components/client/ideas/bathroom/overview/overview-revolution.vue";
import OverviewCategories from "@/components/client/ideas/bathroom/overview/overview-categories.vue";
import OverviewPopularSlides from "@/components/client/ideas/bathroom/overview/overview-popular-slides.vue";
import OverviewExploration from "@/components/client/ideas/bathroom/overview/overview-exploration.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
        OverviewRevolution,
        OverviewCategories,
        OverviewPopularSlides,
        OverviewExploration,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        subjects: [
                    {	
                        title: "욕실 도자기",
                        text: "변기, 비데, 소변기, 세면대 등 다양한 디자인을 찾아보세요.",
                        image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:5869d7a1-bf4a-43e3-9e4b-52ebc2a26edd/onovo-washbasin-white-mirror-round-and-toilet-white.jpg",
                        link: "/b/badkeramik-kollektionen/"
                    },
                    {
                        title: "욕실 가구 및 거울",
                        text: "고품질 가구 디자인과 우아한 거울.",
                        image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:d0adfbf5-4533-4f6d-bca9-efa56f1ce072/antao-white-washbasin-wooden-furniture.jpg",
                        link: "/b/badmoebel-spiegel-kollektionen/"
                    },
                    {
                        title: "웰빙",
                        text: "욕조와 샤워 트레이로 집에서 웰니스를 경험해 보세요.",
                        image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:cfbb75f4-97c9-426a-91c2-d6e2d23183de/antao-bath-tub-white-in-bathroom-with-wall-of-glass.jpg",
                        link: "/b/wellness-kollektionen/"
                    },
                    {
                        title: "욕실 설비",
                        text: "세면대, 비데, 욕조, 샤워 트레이에 포인트를 주는 디자인입니다.",
                        image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:0346a768-e7ef-4e1e-ab8c-af1cb7585afe/black-antao-taps-in-modern-bathroom.jpg.jpg",
                        link: "/b/badarmaturen-kollektionen/"
                    },
                    ],
        themes: [
                    {
                        title: "욕실의 색상",
                        text: "약간의 색상 이론, 영감을 주는 아이디어, Gesa Hansen의 디자이너 팁이 욕실에 딱 맞는 색상을 찾는 데 도움이 될 것입니다.",
                        image: "/images/ideas/bathroom/theme-1.webp",
                        link: "/ideas/bathroom/overview/colours",
                    },
                    {
                        title: "수납공간을 만들어보세요",
                        text: "깔끔한 욕실에 대한 영감 - 영리한 수납 솔루션으로 공간을 최적으로 활용해보세요.",
                        image: "/images/ideas/bathroom/theme-2.webp",
                        link: "/ideas/bathroom/overview/storage",
                    },
                    {
                        title: "욕실 조명을 적절하게 켜세요",
                        text: "세련된 욕실 조명은 일상적인 관리 루틴에 편안한 분위기를 조성합니다.",
                        image: "/images/ideas/bathroom/theme-3.webp",
                        link: "/ideas/bathroom/overview/lighting",
                    },
                    {
                        title: "욕실 디자인을 위한 팁",
                        text: "올바른 조치를 취하면 욕실을 세련된 웰빙의 오아시스로 바꿀 수 있습니다. 무엇을 고려해야 합니까?",
                        image: "/images/ideas/bathroom/theme-4.webp",
                        link: "/ideas/bathroom/overview/installing",
                    },
                ],
        collections: [
                        {
                            title: "건축학",
                            text: "다양한 제품과 기하학적 형태를 통해 활용도가 높은 욕실 디자인.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:ce488b2d-f4ce-4f0a-9c85-c003c4d16230/architectura-bath-collection.jpg",
                            link: "/b/architectura/"
                        },
                        {
                            title: "안타오",
                            text: "자연에서 영감을 받은 부드러운 비대칭 곡선.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:9dd88290-db13-4f87-8c75-e760c67de0fa/antao-bathroom-green-bath-tub.jpg",
                            link: "/b/antao/"
                        },
                        {
                            title: "아벤토",
                            text: "모던한 디자인과 기능성, 넉넉한 수납공간.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:6a3d06b6-525a-47de-a2c9-d2c144990c5a/avento-bathtub-white-washbasin-with-mirror-round-and-shower-tray.jpg",
                            link: "/b/avento/"
                        },
                        {
                            title: "콜라로",
                            text: "미적 감각, 기능성, 편안함을 갖춘 섬세하고 시대를 초월한 디자인입니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:5ae70e5e-a557-4d88-b5a0-41dd620bd724/collaro-bath-tub-in-bathroom-with-wooden-floor.jpg",
                            link: "/b/collaro/"
                        },
                        {
                            title: "피니언",
                            text: "순수한 디자인, 다양한 조합, 혁신적인 조명.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:0d520615-7d92-484f-9bc6-f8cf3cd3bfe3/finion-washbasins-with-mirrors-illuminated-bathtub-white-and-shower-tray-glazed.jpg",
                            link: "/b/finion/"
                        },
                        {
                            title: "경의",
                            text: "클래식함과 모던함이 동시에 - 현대적인 욕실을 위한 고품질의 우아함.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:b91613f1-469c-49ea-8f66-daa6172be911/hommage-bathroom-with-woman-leaning-against-bathtub-washbasin-black.jpg",
                            link: "/b/hommage/"
                        },
                        {
                            title: "루프 앤 프렌즈",
                            text: "꿈에 그리던 욕실을 위한 다양한 모양, 크기, 색상의 유연성.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:e80d56b2-fe55-4dc4-9217-653fa1744ec8/loop-friends-bathtub-in-japanese-style-bathroom.jpg",
                            link: "/b/loop-friends/"
                        },
                        {
                            title: "메멘토 2.0",
                            text: "정확한 윤곽, 섬세한 외관, 욕실의 순수하고 가벼운 느낌.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:17d11cb6-1dba-4eb3-b4e3-6b3215278939/memento-2.0-bathroom-collection-in-black.jpg.jpg",
                            link: "/b/memento-20/"
                        },
                        {
                            title: "오.노보",
                            text: "매력적인 가격 대비 성능을 갖춘 현대적인 욕실 디자인.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:8f5bb399-e818-4202-ac71-6e118c166cca/onovo-washing-bath-tub-and-washbasin-in-modern-green-bathroom.jpg",
                            link: "/b/onovo/"
                        },
                        {
                            title: "서브웨이 3.0",
                            text: "가족 및 손님용 욕실을 위한 다양한 기능과 오래 지속되는 디자인.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:7d83bb55-03cb-4a32-a91b-91f0dd316fae/subway30-bathroom-furniture-in-lightblue-bathroom.jpg",
                            link: "/b/subway-30/"
                        },
                        { 
                            title: "벤티첼로",
                            text: "모든 욕실에 어울리는 간단한 플로팅 디자인과 기능성.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:b347fbf6-7714-4fcb-9bbc-07decca5fbec/venticello-bathtub-white-washbasin-light-brown-toilet-white.jpg",
                            link: "/b/venticello/"
                        }
                        ],
collectionHighlight: [
                        {
                            title: "아르티스",
                            text: "다양한 색상, 모양, 크기를 갖춘 Artis 조리대 세면대는 세탁 공간을 눈길을 사로잡는 공간으로 바꿔줍니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:06783e44-6221-4552-b789-0cab6f039a9d/artis-washbasin-red-in-red-bathroom.jpg",
                            link: "/collection/artis/"
                        },
                        {
                            title: "코눔",
                            text: "탁월한 디자인, 설득력 있는 기술: 독특한 Conum 피팅을 사용하면 욕실에서 개성을 표현할 수 있습니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:a9de8ae2-da5d-4699-981a-5a568643afcf/conum-bathtub-tap-matt-black-with-bathtub-in-modern-bathroom.jpg",
                            link: "/collection/conum/"
                        },
                        {
                            title: "테아노",
                            text: "섬세한 디자인의 독립형 욕조인 Theano는 웰빙 천국에서 확실한 별이자 평화의 안식처입니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:27b1d32a-fa1f-4db8-ad07-477dd3213550/theano-bathtub-white-with-big-window.jpg",
                            link: "/collection/theano/"
                        },
                        {
                            title: "월웨이",
                            text: "혁신적인 Wallway 샤워 시스템은 미적 아름다움과 기능성을 결합하여 기분 좋은 샤워를 위한 절대적인 하이라이트입니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:f8e9fdbe-6399-47e9-8faa-90f6b0962883/wallway-shower-tray-in-modern-bathroom.jpg",
                            link: "/collection/wallway/"
                        }
                        ]   
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>