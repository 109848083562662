<template>
    <client-page>
        <shop-product-visual wide image="https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/ultrawide/1600x/dam/jcr:865e9f8c-46e3-4b74-9fdf-590d52410e85/antao-bath-tub-in-stylish-bathromm.jpg"  title="주방 컬렉션" />
        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-40px">
            </v-container>
        </page-section>


        <page-section id="1">
            <v-container>
				<h3 class="text-left mb-6">11개 컬렉션</h3>
                <v-row class="row--lg">
                    <template v-for="item in collections">
                        <v-col :key="item" cols="6" md="4">
                            <v-card tile flat :to="item.link">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>




    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import OverviewRevolution from "@/components/client/ideas/bathroom/overview/overview-revolution.vue";
import OverviewCategories from "@/components/client/ideas/bathroom/overview/overview-categories.vue";
import OverviewPopularSlides from "@/components/client/ideas/bathroom/overview/overview-popular-slides.vue";
import OverviewExploration from "@/components/client/ideas/bathroom/overview/overview-exploration.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
        OverviewRevolution,
        OverviewCategories,
        OverviewPopularSlides,
        OverviewExploration,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        
        collections: [
                        {
                            title: "아비아 2.0",
                            text: "독특한 손잡이가 달린 클래식하고 우아한 피팅입니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:b58a7502-1b95-4930-9196-7e805d13b3ff/sink-unit-double-bowl-with-tap-in-green-cottage-style-kitchen.jpg",
                            url: "/b/avia/"
                        },
                        {
                            title: "건축학",
                            text: "다양한 가구 스타일에 적합한 균일한 표면과 클래식한 디자인.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:d41db745-ccab-468c-bed6-3da48b6f2662/architectura-kitchen-sink-on-black-surface.jpg",
                            url: "/b/architectura-spuelen/"
                        },
                        {
                            title: "콘도르",
                            text: "기능적으로 잘 고려됨: 작은 주방에 적합한 형식입니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:028d64a7-182d-462d-8b30-d8823bff1041/condor-kitchen-sink-on-wood-countertop-with-como-kitchen-tap.jpg",
                            url: "/b/condor/"
                        },
                        {
                            title: "물 탱크",
                            text: "작업 표면을 쉽게 청소할 수 있는 연속 싱크대.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:4cf4cd41-42f4-431e-ada1-9dfcf4fd2ed8/cisterna-kitchen-sink-with-steel-shower-kitchen-tap.jpg",
                            url: "/b/cisterna/"
                        },
                        {
                            title: "코모",
                            text: "최고의 사용 편의성과 명확한 디자인 언어를 갖춘 단순한 만능 제품입니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:026dbd1c-6de4-478e-af8c-27a3f6a812b5/como-shower-black-on-subway-style-sink.jpg",
                            url: "/b/como/"
                        },
                        {
                            title: "피네라",
                            text: "회전식 에어레이터로 세련된 디자인 포인트.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:b58ceeb1-0f24-4ce8-8f95-083d19e2d453/finera-tap-bronze-with-siluet-sink.jpg",
                            url: "/b/finera/"
                        },
                        {
                            title: "플라비아",
                            text: "시대를 초월한 차분한 디자인과 지능적인 기능성.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:e164c7e2-d27d-4d53-b428-451dd75295c5/flavia-kitchen-sink-with-como-shower-kitchen-tap.jpg",
                            url: "/b/flavia/"
                        },
                        {
                            title: "준스",
                            text: "두 가지 제트 유형과 높이 변형이 가능한 유연하고 확장 가능한 핸드 샤워기입니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:bb62ca24-7bc4-4bd2-a186-459446fd1762/subway-style-kitchen-sink-white-in-kitchen-with-dining-area.jpg",
                            url: "/b/junis/"
                        },
                        {
                            title: "리나라",
                            text: "완벽한 공정을 위한 클래식한 홈 프로파일과 견고한 세라믹 표면",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:0995cb5e-6bcb-4552-af5f-34dc6f806d0c/linara-ceramic-sink-white-with-tap-with-flowing-water.jpg",
                            url: "/b/linara/"
                        },
                        {
                            title: "실루엣",
                            text: "미니멀리스트 가구 스타일을 위한 매혹적인 디자인 하이라이트.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:54513eb3-d657-4458-b5f2-0cd802e426b9/siluet-kitchensink-black-with-finera-tap.jpg",
                            url: "/b/siluet/"
                        },
                        {
                            title: "철강 전문가",
                            text: "쉽게 다룰 수 있도록 자석 홀더가 있는 360° 회전 핸드 샤워기.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:aaad0497-aaac-4de5-8d6e-8d99074d1de2/steel-expert-2-tap-with-flowers.jpg",
                            url: "/b/steelexpert/"
                        },
                        {
                            title: "스틸 샤워",
                            text: "단순하고 현대적인 디자인이 견고한 스테인리스 스틸과 만났습니다.",
                            image: "https://cdn.villeroyb-prod.magnolia-platform.io/.imaging/focalpoint/400x300/dam/jcr:2aa22350-3849-4531-83a7-2531dafca78e/timeline-60-flat-sink-grey-with-wine-and-olives.jpg",
                            url: "/b/steelshower/"
                        }
                        ],
 
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>